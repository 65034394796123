














































import { IUserDetail } from '@/models';
import { authSearvice } from '@/services';
import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api';
import { InputBox } from '@/components';
import { REFERENECE_TYPE } from '@/utils';
import { RountName } from '@/router';

export default defineComponent({
  name: 'AuthProfile',
  components: {
    InputBox,
  },
  setup(_, { root }) {
    const isLoading = ref(false);

    const userData = reactive<IUserDetail>({
      email: '',
      nickName: '',
      introduction: '',
      position: '',
      profileUrl: '',
      referenceUrls: [
        {
          type: REFERENECE_TYPE.Github,
          url: '',
        },
        {
          type: REFERENECE_TYPE.Linkedin,
          url: '',
        },
        {
          type: REFERENECE_TYPE.Blog,
          url: '',
        },
      ],
      userId: 0,
    });

    async function fetchUserDetail() {
      if (isLoading.value) return;

      isLoading.value = true;

      try {
        const response = await authSearvice.getDetail();

        userData.email = response.email;
        userData.nickName = response.nickName;
        userData.introduction = response.introduction;
        userData.position = response.position;
        userData.profileUrl = response.profileUrl;

        if (response.referenceUrls.length > 0) {
          response.referenceUrls.forEach(item => {
            userData.referenceUrls.forEach(item2 => {
              if (item.type === item2.type) {
                item2.url = item.url;
              }
            });
          });
        }

        userData.userId = response.userId;
      } catch (error) {
        //
      } finally {
        isLoading.value = false;
      }
    }

    async function handleClickSubmit() {
      if (isLoading.value) return;

      isLoading.value = true;

      try {
        await authSearvice.updateUser({
          introduction: userData.introduction,
          nickName: userData.nickName,
          position: userData.position,
          profileUrl: userData.profileUrl,
          referenceUrls: userData.referenceUrls,
        });

        alert('수정되었습니다.');

        root.$router.push({ name: RountName.Mypage });
      } catch {
        // pass
      } finally {
        isLoading.value = false;

        fetchUserDetail();
      }
    }

    onMounted(() => {
      fetchUserDetail();
    });

    return {
      userData,

      handleClickSubmit,
    };
  },
});
